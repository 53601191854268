import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { mq } from '../utils/helpers';
import Typography from 'typography';

import {
  Footer,
  FlatBoxes,
  Layout,
  Boxes,
  Slider,
  HomeText,
} from '../containers';
import { Container, HomeTextContainer, Header } from '../styled';
import styled from 'styled-components';

class ProjectPageTemplate extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
  }

    getProjectData() {
        const { data } = this.props;
        return data.allMarkdownRemark.edges.filter(el =>  el.node.frontmatter.contener == this.props.pageContext.contener)[0].node.frontmatter;
    }

    getData(key) {
        const { data } = this.props;
        return data.allMarkdownRemark.edges.filter(el => el.node.frontmatter[key])[0].node.frontmatter;
    }

    unicodeToChar(text) {
        return text.replace(/\\u[\dA-Fa-f]{4}/g,
            function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
            });
    }

  render() {
    const { data } = this.props;
    const { projectboxes, slides, info } = this.getProjectData();
    const { contact } = this.getData('contact');

    return (
      <Layout renderWithChat>
        <Slider slides = {slides} />

          <HomeTextContainer>
              <Container>
              {info != null?
              <ProjectTextContainer style={{marginTop:'2rem'}}>
                  <ProjectTextColumn>
                    <ColumnHeader>Проект/клиент</ColumnHeader>
                      <p style={{fontSize: '30px'}}
                         dangerouslySetInnerHTML={{ __html: this.unicodeToChar(info[0].client) }}
                      ></p>
                  </ProjectTextColumn>
                  <ProjectTextColumn style={{flex:2}}>
                      <ColumnHeader>Описание</ColumnHeader>
                      <p style={{fontSize: '16px', fontWeight:'500'}}
                          dangerouslySetInnerHTML={{ __html: this.unicodeToChar(info[1].description) }}
                      ></p>
                  </ProjectTextColumn>
                  <ProjectTextColumn>
                      <ColumnHeader>Роль</ColumnHeader>
                      <p style={{fontSize:'14px', lineHeight:'2rem'}}>{info[2].roles.map((role ,rowindex) => {
                          return (<span style={{display:'block'}}>{role}</span>)
                      })}</p>
                  </ProjectTextColumn>
              </ProjectTextContainer>
                      :<div style={{marginTop:'3rem'}}></div>}
              </Container>
          </HomeTextContainer>

        <HomeTextContainer>
          <Container>
              <Header>Проекты</Header>
          </Container>
        </HomeTextContainer>

        <Boxes data={projectboxes} />

        <Footer contactSectionContent={contact} />
      </Layout>
    );
  }
}

export default ProjectPageTemplate;

const ProjectTextContainer = styled.div`
  position: relative;
  margin-top:1.5rem;
  display:flex;
  justify-content: space-between;  
  
    ${mq.mq960`
        display:block;
    `}
`;

const ProjectTextColumn = styled.div`
  position: relative;
  margin-top:1.5rem;
  flex: 1; 
  margin-right:1rem;
`;

const ColumnHeader = styled.h4`
    color: rgba(255,255,255,0.4);
    font-size: 16px;
    font-family: 'Galano Grotesque Light', 'Galano Grotesque Regular','Helvetica','Segoe UI','Helvetica Neue','Arial',sans-serif;
    font-weight: normal;
`;

export const query = graphql`
  query {
    filmy: file(relativePath: { eq: "filmy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 460, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    digital: file(relativePath: { eq: "digital.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    design: file(relativePath: { eq: "design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            contener
            slides {
                slide {
                    image_url
                    {
                      childImageSharp
                      {
                          fluid (maxWidth: 3080, quality: 100) 
                          {
                            ...GatsbyImageSharpFluid
                          }
                      }
                    }
                    
                    video_url {
                        publicURL
                    }
                    
                }
            }
            info {
               client
               description
               roles
            }
            projectboxes {
              row {
                box {
                  title
                  destination
                  image_url {
                    childImageSharp {
                      fluid(maxWidth: 3080, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }

                  hover_url {
                    publicURL
                  }

                 }
              }
            }
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
          }
        }
      }
    }
  }
`;
